<template>
  <page-view :title="title">
    <div class="oem_header_wrapper">
      <a-row :gutter="48" class="row">
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: '0', marginRight: '19px' }"
        >
          <div class="filter_item">
            <span>网站名称</span>
            <a-input
              placeholder="网站名称"
              class="filter_input"
              v-model.trim="queryParam.siteTitle"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: '0', marginRight: '19px' }"
        >
          <div class="filter_item">
            <span>域名</span>
            <a-input
              placeholder="域名"
              class="filter_input"
              v-model.trim="queryParam.host"
            />
          </div>
        </a-col>
        <a-col
          :lg="5"
          :md="12"
          :sm="24"
          :style="{ padding: '0', marginRight: '19px' }"
        >
          <div class="filter_item">
            <span>用户ID</span>
            <a-input
              placeholder="用户ID"
              class="filter_input"
              v-model.trim="queryParam.uid"
            />
          </div>
        </a-col>

        <a-col
          :lg="3"
          :md="12"
          :sm="24"
          :style="{ paddingLeft: '0', paddingRight: '0' }"
        >
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="
                () => {
                  $refs.table.refresh(false);
                }
              "
              >查询</a-button
            >
            <a-button @click="insert">新增</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="oem_table_wrapper">
      <s-table
        ref="table"
        rowKey="id"
        :columns="columns"
        :data="dataSource"
        size="small"
      >
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.uid)"
              @mouseover="handleMouseover1(record)"
              @mouseout="handleMouseout1(record)"
            >
              {{
                text.substring(0, 4) +
                  "……" +
                  text.substring(text.split("").length - 4)
              }}
              <span class="uid_child" v-show="record.show1">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <span slot="serial" slot-scope="text, record, index">{{
          index + 1
        }}</span>
        <template slot="logo" slot-scope="text">
          <img
            v-if="text"
            :src="text"
            @click="() => (preview = { show: true, image: text })"
            style="width: 45px;height: 45px;"
          />
        </template>
        <template slot="wechat" slot-scope="text">
          <img
            v-if="text"
            :src="text"
            @click="() => (preview = { show: true, image: text })"
            style="width: 45px;height: 45px;"
          />
        </template>
        <!-- 成本价 -->
        <template slot="plus" slot-scope="text">
          <span style="color:#F93244">{{ text }}</span>
        </template>
        <!-- 推广提成 -->
        <template slot="inviter" slot-scope="text">
          <span style="color:#F93244">{{ text }}</span>
        </template>
        <template slot="inviterUid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              v-if="text.split('').length > 8"
              class="uid_fat"
              @click="copyInfo(record.inviterUid)"
              @mouseover="handleMouseover2(record)"
              @mouseout="handleMouseout2(record)"
            >
              {{
                text.substring(0, 4) +
                  "……" +
                  text.substring(text.split("").length - 4)
              }}
              <span class="uid_child" v-show="record.show2">{{ text }}</span>
            </span>
            <span v-else>{{ text }}</span>
          </a-tooltip>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a @click="update(record)">修改</a>
          <a style="margin-left: 24px;" @click="insert(record)">复制</a>
          <!-- <a-popconfirm
            style="margin-left: 24px; color:#F93244"
            title="确认删除，不可恢复哦?"
            @confirm="() => deleteOem(text)"
          >
            <a href="javascript:;">删除</a>
          </a-popconfirm> -->
        </template>
      </s-table>
    </div>
    <a-modal
      :title="modalTitle"
      :width="720"
      :visible="edit.show"
      v-if="edit.show"
      :maskClosable="false"
      @cancel="handleCancel"
      @ok="handleOk"
      :footer="false"
    >
      <div>
        <div class="setting_title">基本设置</div>
        <a-form :hideRequiredMark="true">
          <a-form-item
            label="自定义域名"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input
              class="domain_name"
              placeholder="二级域名，建议3-6位字母"
              v-model="edit.host"
            />
          </a-form-item>
          <a-form-item
            label="用户ID"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input
              class="input_width"
              placeholder="请输入用户ID"
              v-model="edit.uid"
            />
          </a-form-item>
          <a-form-item
            label="推广人ID"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input
              class="input_width"
              placeholder="请输入推广人ID"
              v-model="edit.inviterUid"
            />
          </a-form-item>
          <a-form-item
            label="公司名称"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input
              class="input_width"
              placeholder="请输入公司名称"
              v-model="edit.companyName"
            />
          </a-form-item>
          <a-form-item
            label="网站名称"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input
              class="input_width"
              placeholder="请输入网站名称"
              v-model="edit.systemTitle"
            />
          </a-form-item>
          <a-form-item
            label="网站logo："
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 12 }, sm: { span: 8 } }"
          >
            <a-upload
              :multiple="false"
              :showUploadList="false"
              list-type="picture"
              :customRequest="customRequest"
              accept="image/gif, image/png, image/jpeg, image/webp"
            >
              <div
                v-if="siteLogo"
                style="position:relative"
                @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave"
              >
                <img :src="siteLogo" class="priview_image" />
                <div v-if="editTip" class="edit_tip">重新上传</div>
              </div>
              <div class="picture_upload" v-else>
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-form>
      </div>
      <div class="commission_setting">
        <div class="setting_title">提成设置</div>
        <a-form :hideRequiredMark="true">
          <a-form-item
            label="卖出价"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 24 } }"
          >
            <a-input-number
              :min="0"
              :precision="2"
              class="input_width"
              placeholder="卖出价"
              v-model="edit.sell"
            />
          </a-form-item>

          <a-form-item
            label="成本价"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 24 } }"
          >
            <a-input-number
              :min="0"
              :precision="2"
              class="input_width"
              placeholder="请输入"
              v-model="edit.plus"
            />
          </a-form-item>
          <a-form-item
            label="下级溢价"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 24 } }"
          >
            <a-input-number
              :min="0"
              :precision="2"
              class="input_width"
              placeholder="请输入"
              v-model="edit.part"
            />
          </a-form-item>

          <a-form-item
            label="用户推广提成"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 24 } }"
          >
            <a-input-number
              :min="0"
              :precision="2"
              class="input_width"
              placeholder="请输入"
              v-model="edit.inviter"
            />
          </a-form-item>
          <a-form-item
            label="商品加价"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 15 }, sm: { span: 24 } }"
          >
            <a-input-number
              :min="0"
              :precision="2"
              class="input_width"
              placeholder="请输入"
              v-model="edit.goodsPart"
            />
          </a-form-item>
        </a-form>
      </div>
      <div class="service_setting">
        <div class="setting_title">客服设置</div>
        <a-form :hideRequiredMark="true">
          <a-form-item
            label="QQ客服"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-input
              class="input_width"
              placeholder="请输入QQ账号"
              v-model="edit.qq"
            />
          </a-form-item>
          <a-form-item
            label="微信客服"
            :labelCol="{ lg: { span: 5 }, sm: { span: 24 }, md: { span: 24 } }"
            :wrapperCol="{ lg: { span: 8 }, sm: { span: 8 } }"
          >
            <a-upload
              :multiple="false"
              :showUploadList="false"
              listType="picture"
              :customRequest="customRequestWechat"
              accept="image/gif, image/png, image/jpeg, image/webp"
            >
              <div
                v-if="wechat"
                style="position:relative"
                @mouseenter="handleMouseEnter2"
                @mouseleave="handleMouseLeave2"
              >
                <img v-if="wechat" :src="wechat" class="priview_image" />
                <div v-if="editTip2" class="edit_tip">重新上传</div>
              </div>

              <div v-else class="picture_upload">
                <a-icon type="plus" />
                <div class="ant-upload-text">上传图片</div>
              </div>
            </a-upload>
          </a-form-item>
        </a-form>
      </div>

      <a-button @click="handleOk" type="primary" class="setting_btn">{{
        btnname
      }}</a-button>
    </a-modal>
    <a-modal
      :visible="preview.show"
      :footer="null"
      @cancel="() => (preview.show = false)"
    >
      <img style="width: 100%" :src="preview.image" />
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from "@/components";
import { PageView } from "@/layouts";
import {
  oemList,
  oemInsert,
  oemUpdate,
  oemDelete,
  filePreUpload,
  uploadOSS
} from "@api";
import { formatDate, uuid } from "@/utils/util";

export default {
  data() {
    return {
      modalTitle: "",
      btnname: "",
      btn: true,
      baseUrl: "",
      editTip: false,
      editTip2: false,
      preview: { image: "", show: false },
      wechat: "",
      siteLogo: "",
      edit: { show: false },
      queryParam: { enable: true },
      columns: [
        {
          title: "序号",
          scopedSlots: { customRender: "serial" }
        },
        {
          title: "用户ID",
          dataIndex: "uid",
          scopedSlots: { customRender: "uid" }
        },
        {
          title: "图标logo",
          dataIndex: "logo",
          scopedSlots: { customRender: "logo" }
        },
        {
          title: "域名",
          dataIndex: "host",
          width: 150
        },
        {
          title: "网站名称",
          dataIndex: "siteTitle"
        },

        {
          title: "QQ号码",
          dataIndex: "qq"
        },
        {
          title: "微信二维码",
          dataIndex: "wechat",
          scopedSlots: { customRender: "wechat" }
        },
        {
          title: "卖出价",
          dataIndex: "sell"
        },
        {
          title: "成本价",
          dataIndex: "plus",
          scopedSlots: { customRender: "plus" }
        },
        {
          title: "下级溢价",
          dataIndex: "part"
        },
        {
          title: "推广提成",
          dataIndex: "inviter",
          scopedSlots: { customRender: "inviter" }
        },
        {
          title: "商品加价",
          dataIndex: "goodsPart"
        },
        {
          title: "推广人ID",
          dataIndex: "inviterUid",
          scopedSlots: { customRender: "inviterUid" }
        },

        {
          title: "修改时间",
          dataIndex: "updateTime",
          customRender: formatDate
        },
        {
          title: "操作",
          dataIndex: "id",
          scopedSlots: { customRender: "operation" }
        }
      ],
      dataSource: parameter => {
        return oemList(Object.assign(parameter, this.queryParam)).then(
          result => {
            result.data.list.forEach(item => {
              item.show1 = false;
              item.show2 = false;
            });
            return result.data;
          }
        );
      }
    };
  },
  created() {},
  components: {
    PageView,
    STable
  },
  methods: {
    handleMouseEnter() {
      this.editTip = true;
    },
    handleMouseLeave() {
      this.editTip = false;
    },
    handleMouseEnter2() {
      this.editTip2 = true;
    },
    handleMouseLeave2() {
      this.editTip2 = false;
    },
    handleMouseover1(record) {
      record.show1 = true;
    },
    handleMouseout1(record) {
      record.show1 = false;
    },
    handleMouseover2(record) {
      record.show2 = true;
    },
    handleMouseout2(record) {
      record.show2 = false;
    },

    deleteOem(id) {
      oemDelete(id).then(res => {
        this.handleRes(res);
      });
    },
    update(record) {
      record.show = true;
      this.siteLogo = record.logo;
      this.wechat = record.wechat;
      this.edit = JSON.parse(JSON.stringify(record));
      //修改//复制
      if (this.edit.host) {
        this.modalTitle = "修改下级分站";
        this.btnname = "确认修改";
        // this.baseUrl =
        //   "." + this.edit.host.replace(this.edit.host.split(".")[0] + ".", "");
        // this.edit.host = this.edit.host.replace(this.baseUrl, "");
      } else {
        //新增
        this.btnname = "确认新增";
        this.modalTitle = "新增下级分站";
        this.baseUrl = ".lipw.com";
      }
    },
    insert(record) {
      if (record) {
        // delete record.id;
        // delete record.uid;
        this.update(record);
      } else {
        this.edit = { show: true };
      }
    },
    customRequest(fileData) {
      this.uploadProcessing(fileData, "siteLogo");
    },
    uploadProcessing(fileData, imgKey) {
      // TODO 1 上传到OSS
      filePreUpload()
        .then(response => {
          const data = response.data;
          const url = data.url;
          const prefix = data.key;
          delete data.url;
          delete data.key;
          const array = fileData.file.name.replace(" ", "").split("."); // 把文件名数组化
          let suffix = ""; // 后缀
          if (array.length > 1) {
            suffix = `.${array.pop().toLowerCase()}`;
          }
          const formData = new FormData();
          Object.keys(data).forEach(it => {
            formData.append(it, data[it]);
          });
          const key = `${prefix}/${uuid()}${suffix}`; // 图片访问路径
          formData.append("key", key);
          formData.append("file", fileData.file);
          uploadOSS(url, formData)
            .then(response => {
              // 上传成功，设置图片访问路径
              this[imgKey] = "http://cache.wodh.cn/" + key;
              this.$message.success("上传成功.");
              return fileData.onSuccess(); // 上传成功了
            })
            .catch(error => {
              this.$message.error("上传异常." + error);
              return fileData.onError(); // 拒绝上传操作
            });
        })
        .catch(error => {
          this.$message.error("授权异常." + error);
          return fileData.onError(); // 拒绝上传操作
        });
      // TODO 2 引入 lrz 在前端压缩图片 获得图片的base64后保存到服务端
      /*if (data.file.size /1024 > 1024 * 2) {
        this.$message.error("请上传小于2M的文件");
        return;
      }
      const that = this;
      lrz(data.file).then(function(rst) {
        //成功时执行
        that.picture = rst.base64;
        console.log('rst', rst);
      }).catch(function(error) {
        //失败时执行
        console.log('error', error);
      }).always(function() {
        //不管成功或失败，都会执行
      });*/
    },
    customRequestWechat(fileData) {
      this.uploadProcessing(fileData, "wechat");
    },
    handleCancel() {
      this.edit = { show: false };
    },
    handleOk() {
      const edit = this.edit;
      // 效验价格设置
      // let priceCheck = true
      // try { // {"puls": 1, "subtract": 1, "multiply": 1}
      //   if (edit.priceOut || edit.priceIn) {
      //     const priceOut = JSON.parse(edit.priceOut);
      //     const priceIn = JSON.parse(edit.priceIn);
      //     if ((typeof priceOut.puls) === 'number' && (typeof priceOut.subtract) === 'number' && (typeof priceOut.multiply) === 'number' && (typeof priceIn.puls) === 'number' && (typeof priceIn.subtract) === 'number' && (typeof priceIn.multiply) === 'number') {
      //       priceCheck = false;
      //     }
      //   }
      // } catch (e) { console.log('e', e);}
      // if (priceCheck) {
      //   this.$message.error('快递价格 效验失败, 请按照规则填写');
      //   return false;
      // }
      // if (edit.sell <= 0) {
      //   return this.$message.error('卖出加价必须大于0');
      // }
      if (edit.sell < edit.inviter) {
        return this.$message.error("卖出价需要大于用户推广提成");
      }
      edit.type = 2;
      edit.logo = this.siteLogo;
      edit.wechat = this.wechat;
      //域名拼接
      // edit.host = edit.host + this.baseUrl;
      if (edit.id) {
        oemUpdate(edit).then(res => {
          this.handleRes(res);
        });
      } else {
        oemInsert(edit).then(res => {
          this.handleRes(res);
        });
      }
    },
    handleRes(res) {
      if (res.success) {
        this.$message.success("成功");
        this.$refs.table.refresh(false);
        this.edit = { show: false };
      } else {
        this.$message.error(res.msg);
      }
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.oem_header_wrapper {
  padding: 24px 56px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  .row {
    margin-left: 0 !important;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
}
.oem_table_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: PingFang SC;
  padding: 24px;
}

.setting_title {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 24px;
}
.picture_upload {
  cursor: pointer;
  width: 104px;
  height: 104px;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .ant-upload-text {
    margin-top: 12px;
    color: #1890ff;
  }
}
.domain_name {
  width: 387px;
}
.priview_image {
  width: 104px;
  height: 104px;
  // cursor: pointer;
  // position: relative;
}
.edit_tip {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 104px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  color: #fff;
  background-color: #595959;
}
.extension_desc {
  height: 100%;
  margin-left: 32px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.extension_money {
  color: #1890ff;
}
.input_width {
  width: 240px;
  margin-right: 8px;
}
.icon {
  color: #1890ff;
}
.setting_btn {
  margin-left: 132px;
  // margin-bottom: 64px;
}
.top5 {
  margin-bottom: 8px;
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
/deep/ .ant-modal-body {
  padding: 24px 40px 40px;
}
</style>
